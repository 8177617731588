import React from 'react'

interface Props {
  title: string
  bgColor?: string
  textColor?: string
  subTitle?: string
  alignText?: string
}

const HeroSimple: React.FC<Props> = ({
  title,
  bgColor = 'gradient-3-bby',
  textColor = 'text-white',
  subTitle,
  alignText = 'text-left',
}: Props) => (
  <div className={`mx-auto max-w-1920 ${bgColor} h-64`}>
    <div className="flex h-full flex-wrap content-center">
      <div className={`container space-y-2 ${alignText} ${textColor}`}>
        <h1 className={`leading-none`}>{title}</h1>
        <p>{subTitle}</p>
      </div>
    </div>
  </div>
)

export default HeroSimple
