import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import ContentfulRichText from '../components/ContentfulRichText'
import { ContentfulPage, ContentfulText } from '../../types/graphql-types'
import SEO from '../components/SEO'
import Hero from '../components/Hero/HeroSimple'
import Layout from '../components/Layout/Spanish/Layout'

interface Props {
  data: {
    legalSpanish: ContentfulPage
    contentfulText: ContentfulText
  }
}

const Content = styled.section`
  &.has-toc {
    ol:first-of-type {
      li {
        ${tw`mb-4`}
        a {
          ${tw`block`}
        }
        p {
          ${tw`md:grid grid-cols-2 md:w-3/4 gap-4`}
        }
      }
    }
  }
  h2 {
    ${tw`leading-none mb-3 pb-2 md:mb-5`}
  }
  h3 {
    ${tw`mb-3`}
  }
  p {
    ${tw`mb-8`}
  }

  .bg-gray-300 {
    p {
      ${tw`last:mb-0`}
    }
  }

  ul {
    ${tw`list-disc ml-5 mb-8`}
    ul {
      list-style-type: circle;
    }
  }

  ol {
    ${tw`list-decimal ml-5 mb-8`}
    ol {
      list-style-type: lower-alpha;
      li {
        p {
          ${tw`mb-0`}
        }
      }
      ol {
        list-style-type: lower-roman;
      }
    }
  }

  b {
    ${tw`font-bold`}
  }
`

export const query = graphql`
  query PageSpanish($slug: String!) {
    legalSpanish: contentfulPage(
      slug: { eq: $slug }
      type: { eq: "legal spanish" }
      node_locale: { eq: "es-US" }
    ) {
      title
      seoTitle
      seoDescription
      slug
      type
      class
      content {
        __typename
        raw
        references {
          ... on ContentfulCustomBlock {
            __typename
            id
            contentful_id
            classes
            content {
              raw
            }
          }
          ... on ContentfulGatsbyComponent {
            __typename
            contentful_id
            id
            name
          }
          ... on ContentfulText {
            __typename
            contentful_id
            id
            name
            longSimpleText {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`

const LegalSpanish: React.FC<Props> = ({ data }: Props) => {
  return (
    <Layout>
      <SEO
        title={data.legalSpanish?.seoTitle as string}
        description={data.legalSpanish?.seoDescription as string}
      />
      <Hero title={data.legalSpanish.title as string} />
      <Content
        className={`content-section-py ${
          data.legalSpanish?.class === 'has-toc' ? data.legalSpanish?.class : ''
        }`}
      >
        <>
          {data.legalSpanish?.content !== null && (
            <ContentfulRichText document={data.legalSpanish?.content} />
          )}
        </>
      </Content>
    </Layout>
  )
}

export default LegalSpanish
